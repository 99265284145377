export default {
  downloadTemplate: "Descargar",
  exportTemplate: "Plantilla de Exportación",
  icon: "Icono",
  setting: "Configuración",
  sort: "Ordenar",
  transfer: "Transferencia",
  close: "Cerrar",
  closeAll: "Cerrar Todo",
  closeOther: "Cerrar Otro",
  version: "Versión",
  quit: "Salir",
  notice: "Convocatoria",
  business: "Mensajes",
  private: "Chat Privado",
  group: "Chat de Grupo",
  top: "Pin Top",
  jobNo: "ID Agente",
  extension: "Ext.",
  agentStatus: "Estado Ext.",
  userStatus: "Estado del Empleado",
  moveIn: "inicio de sesión",
  moveOut: "Firmar",
  number: "Número de Teléfono",
  task: "Tarea",
  idle: "Gratis",
  dialing: "Marcación",
  incomingCall: "enjuague",
  calling: "Conectado",
  offline: "Agente Desconectado",
  activeing: "Activar",
  hangup: "Colgar",
  answer: "Respuesta",
  stopAutoCall: "Pausa OC",
  reCheckIn: "La cuenta actual ha iniciado sesión en otros dispositivos, inténtelo de nuevo...",
  continueMoveIn: "Continuar para Iniciar Sesión",
  cancelMoveIn: "Cerrar Sesión",
  confirmDisconnect: "¿confirmar para cerrar?",
  notVacantAgent: "No hay agentes libres",
  queueEnd: "La cola de llamadas salientes está completa",
  nextFail: "¡Falló la marcación progresiva!",
  sipClientType: "Tipo de Terminal",
  phone: "Introducir nº",
  call: "Marcar",
  tenantName: "Oficina",
  userInfoName: "Nombre de Usuario",
  defaultLoginName: "Nombre de Usuario",
  deptName: "Departamento",
  positionName: "Posición",
  userInfoCode: "ID de Usuario",
  phoneNum: "Nº de Teléfono",
  fileMd5: "md5",
  originalFilename: "Nombre del Archivo Fuente",
  fileName: "Nombre",
  relativePath: "URS",
  operation: "Acción",
  importType: "Tipo de Importación",
  file: "Texto",
  most: "Menos de",
  onlyUpload: "Sólo Cargar",
  templateType: "Tipo de Plantilla",
  uploadHint: "Arrastre el archivo aquí o haga clic para cargarlo",
  limitHint: "El tamaño del archivo no puede ser superior a",
  mustFile: "Seleccione el archivo que desea cargar",
  mustImportType: "Seleccionar tipo de importación",
  beyondTip: "¡El tamaño de la carga excede el límite!",
  add: "Nuevo",
  deleteAllTips: "Borrar esta nota y todas las funciones subordinadas permanentemente, ¿confirmar?",
  function: "Función",
  functionAndInterface: "Función e interfaz de función Relación ",
  level: "Nivel",
  parentCode: "ID  Padres",
  parentName: "Nombre del padre",
  titleZh: "Nombre de la Función",
  serialNo: "ID",
  orgType: "Tipo de Nota",
  aliasName: "Alias",
  name: "Nombre",
  organization: "Dpto",
  post: "Posición",
  bizLine: "Función",
  dataPermission: "Acceso a Datos",
  deleteTips: "Borrar",
  add1: "Nuevo",
  deleteAllTips1: "Org nota y todas las posiciones subordinadas se eliminarán de forma permanente, ¿confirmar?",
  sysKey: "Plataforma",
  indexCode: "Portada",
  title: "Configuración de la Portada",
  index: "Portada",
  ctsn: "ID de Tarea del Cliente",
  taskSn: "ID de Tarea",
  taskType: "Tipo de Tarea",
  userInfoId: "ID de Operador",
  batchId: "ID de Lote",
  firstStrategyId: "Estrategia de Llamada",
  outboundStrategyId: "Estrategia de Llamada",
  secondStrategyId: "Estrategia de Remarcación",
  batchOrder: "ID de Lote",
  year: "Año",
  month: "月份-未翻译",
  date: "Fecha de la Tarea",
  dateHour: "Hora",
  hour: "Hora",
  callSys: "Tipo de Motor",
  createType: "Crear Tipo",
  businessScenario: "Escenario",
  industryCode: "ID del sector",
  taskClassify: "Tipo de Tarea",
  status: "Estado de la Tarea",
  editStatus: "Editar Estado",
  statusFailType: "Fallo Tipo de Tarea Estado",
  failReason: "Motivo",
  isForcibly: "Forzar Finalización",
  autoDedupFlag: "Deduplicar",
  smsSendFlag: "Enviar SMS",
  hangupSmsId: "ID de la Plantilla SMS",
  hangupSmsName: "Plantilla SMS",
  totalCount: "#Casos",
  formalCount: "# Números de Teléfono",
  testCount: "Recuento de Números de Prueba",
  addDataSuccCount: "# Números de Teléfono Formales",
  addDataFailCount: "#Fallido",
  processCount: "#Llamada",
  cancelCallCount: "#DNC",
  blackListCount: "Lista Negra",
  dedupCount: "Recuento Deduplicado",
  succSyncCount: "Total de Sincronizaciones",
  succSyncRate: "Tasa de Sincronización",
  connCount: "Llamadas Conectadas",
  connRate: "Tasa de Conexión",
  totalTalkingTime: "Duración total de la llamada",
  maxTalkingTime: "Máximo",
  avgTalkingTime: "Avg. Duración de la Llamada",
  feeUnit: "Empleador",
  unitCount: "Base del Empleador",
  unitPrice: "Precio Unitario",
  totalPrice: "合计-未翻译",
  totalFeeCount: "Minutos Facturados",
  ultraShortCount: "# llamadas en 1 segundo",
  ultraShortRatio: "% llamadas en 1 segundo",
  shorterCount: "Recuento de la Duración de las Llamadas Cortas",
  shorterRatio: "Duración corta de la llamada %",
  normalCount: "Recuento de Llamadas Normales",
  normalRatio: "Duración Normal de la Llamada %",
  longerCount: "Conteos de Duración de Llamada Larga",
  longerRatio: "Duración de la Llamada Larga %",
  ultraLongCount: "# Llamadas superiores a 60 segundos",
  ultraLongRatio: "% Llamadas superiores a 60 segundos",
  earliestTime: "Hora de la primera llamada",
  latestTime: "Última hora de llamada",
  outboundNo: "Línea de Llamada",
  callResultStatus: "Estado del Archivo de Resultados de Llamada",
  callResultFilePath: "Ruta del archivo de resultados de llamada",
  callSysSpeechId: "AI ID de Registro",
  speechId: "ID de script",
  speechCode: "ID de Script",
  speechName: "Nombre de la secuencia de comandos",
  productId: "ID del Producto",
  productCode: "Código del Producto",
  beginTime: "Hora de Inicio",
  endTime: "Hora de Finalización",
  taskGroup: "Grupo de tareas",
  taskGroupStatus: "Estado del Grupo de Tareas",
  lastTaskGroup: "Último Grupo de Tareas",
  taskGroupOrder: "ID del grupo Tast",
  taskGroupCallNo: "Grupo de Tareas Dial ID",
  lastTaskId: "ID de la última tarea",
  currentFilterType: "Seleccione Tipo",
  currentFilterContent:
    "El contenido de las reglas de selección de nueva repetición de tarea puede delimitarse con coma.",
  automationFlag: "Tarea Automática",
  isCallBack: "Devolución de Datos",
  recallFlag: "Rellamada",
  recallType: "Tipo de Remarcación",
  currentRecallTimes: "Horario de Remarcado",
  originalRecallTimes: "Tiempos de Remarcación",
  recallTimes: "Tiempos de rellamada",
  recallInterval: "Intervalo de Repetición",
  recallNumGroup: "Rellamada Línea",
  recallNumSmsSendFlag: "Bandera SMS de rellamada",
  recallNumSmsId: "Plantilla SMS de rellamada",
  filterType: "Seleccione Tipo",
  filterContent: "Los criterios de selección pueden estar delimitados por coma！",
  startType: "Tipo de Inicio",
  timedStartTime: "Hora de inicio de la tarea programada",
  excludeRegionOperator: "Zona/ISP excluida",
  changeFlag: "Optimizar",
  changeTime: "Hora de Actualización",
  callerGroupName: "ID de Grupo de Llamadas",
  failCall: "Registros de Llamadas Fallidas",
  notCalled: "Registros no Marcados",
  dataFile: "Archivos de Clientes",
  upload: "Importar",
  strategyFlag: "Aplicar estrategia",
  speechTemplate: "Plantilla de Script",
  fileTips: "¡El archivo no puede estar vacío!",
  endTips: "Tarea Seleccionada",
  automationTips: "¿Automatizar?",
  newRecall: "Rellamada y creación de nuevo registro",
  originRecall: "Registro de Rellamada y sobrescritura",
  editingFlag: "Editar Estado",
  editingName: "Editado por",
  originalRecallFlag: "Rellamada y Sobreescritura",
  completeEdit: "Forzar a completar el estado de edición de tareas",
  insertTestData: "Insertar",
  insertTestDataTips: "¡No se permite editar!",
  sendSMS: "Enviar SMS",
  alarmSetting: "Ajustes de alerta",
  callerGroupId: "Grupo llamante de la 1ª iteración",
  concurrentNumber: "Concurrencia Máx:",
  taskSource: "Tipo de Tarea",
  name1: "Nombre de la Tarea",
  publish: "Publicar",
  revokePublish: "Deshacer Publicar",
  speechConfig: "Configuración",
  recordCheck: "Autenticación de Grabación",
  recordUpload: "Importar Grabación",
  pack: "Paquete",
  remark: "Descripción del Script",
  releaseFlag: "Estado de la Publicación",
  createByName: "Creado por",
  updateTime: "Última Actualización",
  baseInfo: "Información Básica",
  recordingType: "Tipo de Grabación",
  lastNamePrivateFlag: "Confidencializar Apellido",
  understandWay: "ASR",
  speechMatchType: "Tipo de Coincidencia de Pregunta",
  waitingTime: "Tiempo de Espera",
  pauseTime: "Duración de la Pausa",
  recordSilenceThreshold: "Umbral de Energía de Inicio",
  eosSilenceThreshold: "Umbral de energía final",
  interruptMode: "Método de Interrupción",
  interruptRecordSilenceThreshold: "Umbral de energía de interrupción",
  interruptPauseTime: "Interrupción Silencio Horas extras",
  interruptIntervalMs: "Intervalo de Interrupción",
  engineSetting: "Configuración del Motor",
  ttsType: "Canal TTS",
  ttsSpeakerCode: "ID Ponente",
  asrType: "Canal ASR",
  ttsSpeed: "Velocidad TTS",
  ttsIntonation: "Tono TTS",
  ttsVolume: "Volumen TTS",
  prodName: "Nombre del Producto",
  prodCode: "Código del Producto",
  interestRate: "Tipo de Interés",
  status1: "Estado del Producto",
  stageFlag: "Instalación",
  stageType: "Tipo de Plazo",
  repaymentMethod: "Tipo de Pago",
  all: "Total",
  count: "条记录不用翻译",
  orgName: "Depto",
  orgCode: "部门编号-未翻译",
  customerName: "Nombre del Cliente",
  gender: "Género del Cliente",
  company: "Entidad del Contrato",
  customerStage: "Etapa del Caso",
  lastCallTime: "Hora del Último Contacto",
  belongTenantTime: "Hora de Asignación de Arrendatarios",
  followUpType: "Método de seguimiento",
  belongUserInfoName: "Gestor de Clientes",
  phone1: "Teléfono del Cliente",
  level1: "Nivel de caso",
  send: "Enviar",
  label: "Bandera",
  markType: "Tipo de Bandera",
  model: "Modelo",
  modelName: "Nombre del Modelo",
  modelCode: "Código de Modelo",
  modelType: "Tipo de Modelo",
  remark1: "Comentario",
  nomatchScore: "Umbral no igualado",
  classfiy: "Clasificación",
  classfiyName: "Nombre",
  classfiyCode: "Código",
  pretestCallRemind: "预测式外呼活动提醒-未翻译",
  pretestRemind: "请签入系统电话就绪准备参加-未翻译",
  notJoin: "No participar",
  join: "Participar",
  beginTips: "活动即将开始-未翻译",
  moveInCall: "请先签入,再拨打电话",
  mustPhone: "¡Número de teléfono no puede estar vacío!",
  currentCalling: "当前正处于通话中"
}