export default {
  button: {
    add: "New",
    agree: "Agree",
    activation: "Activate",
    all: "Total",
    allChecked: "Select All",
    assign: "Assign",
    batch: "Batch",
    cancel: "Cancel",
    claim: "Claim",
    confirm: "Confirm",
    copy: "Copy",
    copyAdd: "Copy to Add",
    close: "Close",
    closeSearch: "Collapse",
    end: "Terminated",
    create: "Create",
    delete: "Delete",
    detail: "Detail",
    downloadTemplate: "Download",
    download: "Download",
    edit: "Edit",
    empty: "Empty",
    enable: "Enable",
    examinePass: "Approved",
    examineReject: "Declined",
    export: "Export",
    filter: "Select",
    finish: "Completed",
    fold: "Collapse",
    forbidden: "Disable",
    generate: "Generate",
    handle: "In Process",
    import: "Import",
    lock: "Lock",
    login: "Login",
    query: "Search",
    off: "Close",
    open: "Start",
    publish: "Publish",
    resume: "Resume",
    refresh: "Refresh",
    revoke: "Withdraw",
    reject: "Rejec",
    revokeRelease: "Undo Publish",
    sort: "Sort",
    save: "Save",
    spread: "Collapse",
    spreadSearch: "Expand",
    startUp: "Start",
    stop: "Paused",
    submit: "Submit",
    temporarySave: "Staging",
    transfer: "Transfer",
    upload: "Upload",
    unlock: "Unlock",
    void: "Void"
  },
  common: {
    agent: "Agency",
    alter: "Change",
    case: "Case",
    createBy: "Created by",
    customer: "Customer",
    department: "Department",
    entrust: "Product",
    entrustProduct: "Product",
    labelQuery: "Flag Query",
    notData: "No data!",
    operate: "Action",
    product: "Product",
    quantity: "Count",
    remark: "Comment",
    speech: "Script",
    sysCode: "System Type",
    tenant: "Office",
    workplace: "Offices",
    user: "Agent",
    task: "Task",
    createByName: "Created by",
    createTime: "Create Time",
    updateByName: "Operator",
    updateTime: "Process Time"
  },
  tips: {
    autoCallEnd: "Pause first since its status is automated call!",
    copyFail: "Copy failed",
    copySuccess: "Copy finished",
    enter: "Enter",
    pick: "Select",
    required: "can not be empty!",
    select: "Select",
    confirmDelete: "Confirm delete?"
  },
  unit: {
    day: "Day",
    individual: "个不用翻译",
    millisecond: "Millesecond",
    minute: "Minute",
    percentage: "%",
    piece: "件不用翻译",
    position: "byte",
    tenThousandYuan: "Ten Thousand",
    second: "Second",
    yuan: "Peso",
    rice: "Meter"
  }
}