import { shiki, Api } from '../api'
import call from './call'
import {
  Bus,
  FollowUpType,
  FollowUpDataType,
  InboundType,
  QueueType
} from '../enum'
import bus from './bus'
import { useCallStore } from '../store'
import { CallResult } from '../type'

export default {
  // 保存跟进并外呼
  async followAndCall(config: {
    campaignId?: string // 活动id
    date?: string // 日期
    sourceId?: string // 来源id
    followUpDataType: FollowUpDataType // 跟进数据类型
    phoneSourceId?: string // 号码来源id
    phoneSourceField?: string // 号码来源字段
    phone?: string // 号码
    workOrderId?: string // 案件id
  }) {
    // 能否拨打电话
    const status = call.canCall()
    if (!status) {
      return
    }
    const params = Object.assign(config, {
      followUpType: FollowUpType.HumanOutbound // 跟进类型(人工外呼)
    })
    const data = await this.saveFolloUp(params)
    if (data) {
      const callStore = useCallStore()
      // 清空通话结果
      callStore.clearCallResult()
      // 拨打
      call.callOut(data.phone, data.callSn)
      return true
    }
  },
  // 保存跟进记录
  async saveFolloUp(config: {
    campaignId?: string // 活动id
    date?: string // 日期
    businessScenario?: InboundType // 业务场景
    sourceId?: string // 来源id
    phoneSourceId?: string // 号码来源id
    phoneSourceField?: string // 号码来源字段
    followUpType?: FollowUpType // 跟进类型
    followUpDataType: FollowUpDataType // 跟进数据类型
    phone?: string // 号码
    workOrderId?: string // 案件id
  }) {
    const callStore = useCallStore()
    const params = {
      campaignId: config.campaignId,
      date: config.date,
      businessScenario: config.businessScenario,
      sourceId: config.sourceId,
      phoneSourceId: config.phoneSourceId,
      phoneSourceField: config.phoneSourceField,
      followUpType: config.followUpType,
      followUpDataType: config.followUpDataType,
      phone: config.phone,
      sipUser: callStore.agentInfo.ext,
      workOrderId: config.workOrderId
    }
    const path = Api.crmmu + 'wod/saveManualFollowUp'
    const { data } = await shiki.postData(path, params, { failTip: true })
    if (data) {
      callStore.phone = data.phone
      callStore.business = {
        campaignId: config.campaignId || '',
        date: config.date || '',
        id: data.callSn,
        phone: data.phone,
        beFollowUpType: data.beFollowUpType,
        contactName: data.contactName,
        followUpDataType: config.followUpDataType,
        workOrderId: config.workOrderId || '',
        followUpType: config.followUpType || ''
      }
    }
    return data
  },
  // 更新跟进记录
  async updateFollowUp(callResult: CallResult) {
    const callStore = useCallStore()
    const params = Object.assign(
      callStore.business,
      callResult,
      callStore.getwayNumber
    )
    const path = Api.crmmu + 'wod/updateManualFollowUp'
    await shiki.postCode(path, params, { tip: false })
    callStore.callResult = callResult
  },
  // 自动外呼的保存跟进
  async autoCallFollow(
    sourceId: string,
    config?: {
      date?: string // 日期
      contactPhone?: boolean // 是否为联系人电话
      phoneSourceId?: string // 号码来源id
    }
  ) {
    const callStore = useCallStore()
    const queueType = callStore.queueType
    // 参数
    let followUpDataType: FollowUpDataType
    // 电催
    if (queueType === QueueType.Tel) {
      if (config?.contactPhone) {
        followUpDataType = FollowUpDataType.CaseContactPhone
      } else {
        followUpDataType = FollowUpDataType.Case
      }
    }
    // 客户
    if (queueType === QueueType.Customer) {
      if (config?.contactPhone) {
        followUpDataType = FollowUpDataType.CustomerContactPhone
      } else {
        followUpDataType = FollowUpDataType.Customer
      }
    }
    // 保存跟进并拨打电话
    const status = await this.followAndCall({
      sourceId,
      followUpDataType: followUpDataType!,
      phoneSourceId: config?.phoneSourceId || sourceId,
      date: config?.date || callStore.workbench.collection.date,
      phoneSourceField: 'phone'
    })
    callStore.autoCall = true
    if (!status) {
      bus.emit(Bus.AutoCallContinue)
    }
  },
  // 切换队列数据
  async turnPageQueue(type: 'prev' | 'next', queueType: QueueType) {
    const callStore = useCallStore()
    let workbench
    if (queueType === QueueType.Tel) {
      workbench = callStore.workbench.collection
    } else {
      workbench = callStore.workbench.customer
    }
    const params = {
      queueId: workbench.queueId, // 队列id
      serialNo: workbench.serialNo, // 序号
      pageTurningMode: type // 类型 prev上一条 next下一条
    }
    const path = Api.crmmu + 'wod/turnPageJobQueue'
    const { data } = await shiki.getData(path, params)
    if (data) {
      const query = {
        queueId: workbench.queueId, // 队列id
        serialNo: data.index, // 序号
        id: data.id, // id
        total: workbench.total, // 总页码
        isEdit: '1', // 可编辑
        date: data.date
      }
      if (queueType === QueueType.Tel) {
        callStore.workbench.collection = query
      } else {
        callStore.workbench.customer = query
      }
      return query
    }
  },
  // 暂停自动外呼
  stopAutoCall() {
    const callStore = useCallStore()
    callStore.autoCall = false
    call.hangUp()
  },
  // 调解场景
  isCollection(type: InboundType) {
    return [InboundType.Collection].includes(type)
  },
  // 客服场景
  isCustomerService(type: InboundType) {
    return [
      InboundType.Visit, // 回访
      InboundType.Market, // 营销
      InboundType.Notice, // 通知
      InboundType.Debt, // 债务
      InboundType.Complaint, // 投诉
      InboundType.Seek // 咨询
    ].includes(type)
  }
}
