import { ElNotification } from 'element-plus'
import {
  bus,
  call,
  core,
  element,
  useAppStore,
  useCallStore,
  useChatStore,
  useUserStore,
  Bus
} from '@chant'
import router from '../router'

// 消息类型
enum MsgType {
  Heart = '11', // 心跳
  Unread = '12', // 更新未读消息数
  Remind = '13', // 实时提醒
  Account = '14', // 账号在别处登陆
  ChatRoom = '16', // 群聊消息
  ChatRoomUnread = '17', // 群聊消息未读
  Undead = '19', // 永久提示
  PhoneStatus = '22', // 工作手机状态
  PretestRemind = '23' // 预测式外呼活动提醒
}
// 群聊类型
enum ChatRoomType {
  Project = '10', // 项目
  Case = '11' // 案件
}
// 数据集
type Data = {
  chatRoomId: string
  chatRoomType: ChatRoomType
  msgBody?: { content?: any }
  msgType: MsgType
  send: any
}

class Im {
  private ws?: WebSocket // websocket实例
  private isKeep = false // 是否处于连接状态
  private isReconnect = true // 是否需要重连
  private reconnectTimes = 0 // 重连次数
  private heartTimer: any // 心跳timer
  private reconnectTimer: any // 重连timer
  private appStore?: ReturnType<typeof useAppStore> // user store
  private callStore?: ReturnType<typeof useCallStore> // chat store
  private chatStore?: ReturnType<typeof useChatStore> // chat store
  private userStore?: ReturnType<typeof useUserStore> // user store

  // 初始化
  init() {
    // store
    this.appStore = useAppStore()
    this.callStore = useCallStore()
    this.chatStore = useChatStore()
    this.userStore = useUserStore()
    const token = this.userStore?.token
    // websocket初始化
    const url = core.getBaseUrl()
    const protocol = location.protocol.includes('https') ? 'wss' : 'ws'
    this.ws = new WebSocket(`${protocol}://${url}/scxsim/ws/${token}`)
    // open
    this.ws.onopen = () => {
      // 连接状态激活
      this.isKeep = true
      // 心跳检测
      this.heartCheck()
    }
    // message
    this.ws.onmessage = (ret) => {
      // 消息处理
      ret?.data && this.deal(JSON.parse(ret.data))
      // 心跳检测
      this.heartCheck()
    }
    // close
    this.ws.onclose = (ret) => {
      console.log('im onclose:', ret)
      this.isKeep = false
      // 重连
      this.reconnect()
    }
    // error
    this.ws.onerror = (ret) => {
      console.log('im error:', ret)
      this.isKeep = false
      // 重连
      this.reconnect()
    }
  }
  // 断开连接
  close() {
    this.ws?.close()
  }
  // 消息处理
  private deal(result: Data) {
    const content = result.msgBody?.content
    // 心跳
    if (result.msgType === MsgType.Heart) {
      return
    }
    // 账号在别处登陆
    if (result.msgType === MsgType.Account) {
      this.close()
      router.push('/login')
      setTimeout(() => {
        call.logout()
        element.confirm(content)
      }, 500)
      return
    }
    // 群聊
    if (result.msgType === MsgType.ChatRoom) {
      const { projectId } = this.chatStore?.state || {}
      // 项目
      if (result.chatRoomType === ChatRoomType.Project) {
        if (projectId === result.chatRoomId) {
          bus.emit(Bus.ChatProject, content)
        } else {
          // 设置消息未读
          this.send({
            msgType: MsgType.ChatRoomUnread,
            msgBody: JSON.stringify(result.send)
          })
        }
        return
      }
    }
    // 未读消息
    if (result.msgType === MsgType.Unread) {
      this.appStore!.unread = content
    }
    // 提醒
    if (result.msgType === MsgType.Remind) {
      ElNotification({
        message: content,
        duration: 3000
      })
    }
    // 永久提示
    if (result.msgType === MsgType.Undead) {
      ElNotification({
        message: content,
        duration: 0
      })
    }
    // 工作手机
    if (result.msgType === MsgType.PhoneStatus) {
      this.callStore!.agentStatus = content
    }
    // 预测式外呼活动提醒
    if (result.msgType === MsgType.PretestRemind) {
      bus.emit(Bus.PretestRemind, result.msgBody)
    }
    // log
    console.log('im deal:', result)
  }
  // 心跳检测
  private heartCheck() {
    const time = 30000
    this.heartTimer && clearTimeout(this.heartTimer)
    this.heartTimer = setTimeout(() => {
      this.send({ msgType: MsgType.Heart, msgBody: { content: 'ping' } })
      // 如果10秒内服务器没有响应,说明连接已经断开,开始重连
      setTimeout(() => {
        !this.isKeep && this.reconnect()
      }, time + 10000)
    }, time)
  }
  // 重连
  private reconnect() {
    if (this.isReconnect && !this.isKeep) {
      if (this.reconnectTimes > 10) {
        this.isReconnect = false
        return
      }
      this.reconnectTimes++
      this.reconnectTimer && clearTimeout(this.reconnectTimer)
      this.reconnectTimer = setTimeout(() => {
        this.ws?.close()
        this.init()
      }, 10000)
    }
  }
  // 发送
  private send(row: { msgType: MsgType; [key: string]: any }) {
    if (row.msgType !== MsgType.Heart) {
      console.log('im send:', row)
    }
    this.ws?.send(JSON.stringify(row))
  }
}

export default new Im()
