export default {
  downloadTemplate: "Download",
  exportTemplate: "Export Template",
  icon: "Icon",
  setting: "Settings",
  sort: "Sort",
  transfer: "Transfer",
  close: "Close",
  closeAll: "Close All",
  closeOther: "Close Other",
  version: "Version",
  quit: "Exit",
  notice: "Announcement",
  business: "Messages",
  private: "Private Chat",
  group: "Group Chat",
  top: "Pin Top",
  jobNo: "Agent ID",
  extension: "Ext.",
  agentStatus: "Ext. Status",
  userStatus: "Employee Status",
  moveIn: "Sign On",
  moveOut: "Sign Off",
  number: "Phone No.",
  task: "Task",
  idle: "Free",
  dialing: "Dialing",
  incomingCall: "Rining",
  calling: "Being Connected",
  offline: "Agent Offline",
  activeing: "Enabling",
  hangup: "Hang up",
  answer: "Answer",
  stopAutoCall: "Pause OC",
  reCheckIn: "Current account has logged in on other devices, try again?",
  continueMoveIn: "Continue to Sign On",
  cancelMoveIn: "Sign Off",
  confirmDisconnect: "Confirm to sign off?",
  notVacantAgent: "No free agents!",
  queueEnd: "Outbound call queue is complete!",
  nextFail: "Progressive dialing failed!",
  sipClientType: "Terminal Type",
  phone: "Enter No.",
  call: "Dial",
  tenantName: "Office",
  userInfoName: "User Name",
  defaultLoginName: "Username",
  deptName: "Department",
  positionName: "Position",
  userInfoCode: "User ID",
  phoneNum: "Phone No.",
  fileMd5: "md5",
  originalFilename: "Source File Name",
  fileName: "File Name",
  relativePath: "URS",
  operation: "Action",
  importType: "Import Type",
  file: "Text",
  most: "Less than",
  onlyUpload: "Upload Only",
  templateType: "Template Type",
  uploadHint: "Drag file here or click to upload!",
  limitHint: "File size can not larger than",
  mustFile: "Select file to upload",
  mustImportType: "Select import type",
  beyondTip: "Upload size exceeds limit!",
  add: "New",
  deleteAllTips: "Delete this note and all subordinate functions permanently, confirm?",
  function: "Function",
  functionAndInterface: "Function and Function Interface Relationship ",
  level: "Level",
  parentCode: "Parent ID",
  parentName: "Parent Name",
  titleZh: "Function Name",
  serialNo: "ID",
  orgType: "Note Type",
  aliasName: "Alias",
  name: "Name",
  organization: "Dept",
  post: "Position",
  bizLine: "Function",
  dataPermission: "Data Access",
  deleteTips: "Delete",
  add1: "New",
  deleteAllTips1: "Org note and all subordinate positions will be deleted permanently, confirm?",
  sysKey: "Platform",
  indexCode: "Frontpage",
  title: "Frontpage Settings",
  index: "Frontpage",
  ctsn: "Customer Task ID",
  taskSn: "Task ID",
  taskType: "Task Type",
  userInfoId: "Operator ID",
  batchId: "Batch ID",
  firstStrategyId: "Call Strategy",
  outboundStrategyId: "Call Strategy",
  secondStrategyId: "Redial Strategy",
  batchOrder: "Batch ID",
  year: "Year",
  month: "月份-未翻译",
  date: "Task Date",
  dateHour: "Hour",
  hour: "Hour",
  callSys: "Engine Type",
  createType: "Create Type",
  businessScenario: "Scenario",
  industryCode: "Industry ID",
  taskClassify: "Task Type",
  status: "Task Status",
  editStatus: "Edit Status",
  statusFailType: "Failure Type of Task Status",
  failReason: "Reason",
  isForcibly: "Force Complete",
  autoDedupFlag: "Deduplicate",
  smsSendFlag: "Send SMS",
  hangupSmsId: "SMS template ID",
  hangupSmsName: "SMS Template",
  totalCount: "#Cases",
  formalCount: "# Phone Numbers",
  testCount: "Test Numbers Count",
  addDataSuccCount: "# Formal Phone Numbers",
  addDataFailCount: "#Failed",
  processCount: "#Called",
  cancelCallCount: "#DNC",
  blackListCount: "Blacklist Count",
  dedupCount: "Deduplicated Count",
  succSyncCount: "Total Sync Counts",
  succSyncRate: "Sync Rate",
  connCount: "Connected Calls",
  connRate: "Connection Rate",
  totalTalkingTime: "Total Call Duration",
  maxTalkingTime: "Max",
  avgTalkingTime: "Avg. Call Duration",
  feeUnit: "Employer",
  unitCount: "Employer Base",
  unitPrice: "Unit Price",
  totalPrice: "合计-未翻译",
  totalFeeCount: "Billed Minutes",
  ultraShortCount: "# calls within 1 second",
  ultraShortRatio: "% calls within 1 second",
  shorterCount: "Short Call Duration Counts",
  shorterRatio: "Short Call Duration %",
  normalCount: "Normal Call Counts",
  normalRatio: "Normal Call Duration %",
  longerCount: "Long Call Duration Counts",
  longerRatio: "Long Call Duration %",
  ultraLongCount: "# Calls above 60 Seconds",
  ultraLongRatio: "% Calls above 60 Seconds",
  earliestTime: "Earliest Call Time",
  latestTime: "Latest Call Time",
  outboundNo: "Call Line",
  callResultStatus: "Call Result File Status",
  callResultFilePath: "Call Result File Path",
  callSysSpeechId: "AI Record ID",
  speechId: "Script ID",
  speechCode: "Script ID",
  speechName: "Script Name",
  productId: "Product ID",
  productCode: "Product Code",
  beginTime: "Start Time",
  endTime: "End Time",
  taskGroup: "Task Group",
  taskGroupStatus: "Task Group Status",
  lastTaskGroup: "Last Task Group",
  taskGroupOrder: "Tast Group ID",
  taskGroupCallNo: "Task Group Dial ID",
  lastTaskId: "Last Task ID",
  currentFilterType: "Select Type",
  currentFilterContent:
    "The content of selection rules of new task repeat can be delimited with comma.",
  automationFlag: "Auto Task",
  isCallBack: "Return Data",
  recallFlag: "Redial",
  recallType: "Redial Type",
  currentRecallTimes: "Times Redialed",
  originalRecallTimes: "Redial Times",
  recallTimes: "Redial Times",
  recallInterval: "Repeat Interval",
  recallNumGroup: "Redial Line",
  recallNumSmsSendFlag: "Redial SMS Flag",
  recallNumSmsId: "Redial SMS Template",
  filterType: "Select Type",
  filterContent: "select criteria can be delimited by comma！",
  startType: "Start Type",
  timedStartTime: "Scheduled Task Start Time",
  excludeRegionOperator: "Excluded area/ISP",
  changeFlag: "Optimize",
  changeTime: "Update Time",
  callerGroupName: "Caller Group ID",
  failCall: "Failed Call Records",
  notCalled: "Not dialed records",
  dataFile: "Customer Files",
  upload: "Import",
  strategyFlag: "Apply Strategy",
  speechTemplate: "Script Template",
  fileTips: "File can not be empty!",
  endTips: "Selected Task",
  automationTips: "Automate?",
  newRecall: "Redial & create new record",
  originRecall: "Redial & overwrite record",
  editingFlag: "Edit Status",
  editingName: "Edited by",
  originalRecallFlag: "Redial & Overwrite",
  completeEdit: "Force to complete task edit status",
  insertTestData: "Insert",
  insertTestDataTips: "Not allowed to edit!",
  sendSMS: "Send SMS",
  alarmSetting: "Alert Settings",
  callerGroupId: "Caller Group of 1st Iteration",
  concurrentNumber: "Max Concurrency:",
  taskSource: "Task Type",
  name1: "Task Name",
  publish: "Publish",
  revokePublish: "Undo Publish",
  speechConfig: "Settings",
  recordCheck: "Recording Authentication",
  recordUpload: "Import Recording",
  pack: "Package",
  remark: "Script Desc",
  releaseFlag: "Publish Status",
  createByName: "Created by",
  updateTime: "Last Update",
  baseInfo: "Basic Info",
  recordingType: "Recording Type",
  lastNamePrivateFlag: "Privatize Lastname",
  understandWay: "ASR",
  speechMatchType: "Question Match Type",
  waitingTime: "Waiting Time",
  pauseTime: "Pause Duration",
  recordSilenceThreshold: "Start Energy Threshold",
  eosSilenceThreshold: "End Energy Threshold",
  interruptMode: "Interruption Method",
  interruptRecordSilenceThreshold: "Interruption Energy Threshold",
  interruptPauseTime: "Interruption Silence Overtime",
  interruptIntervalMs: "Interruption Interval",
  engineSetting: "Engine Settings",
  ttsType: "TTS channel",
  ttsSpeakerCode: "Speaker ID",
  asrType: "ASR Channel",
  ttsSpeed: "TTS Speed",
  ttsIntonation: "TTS tone",
  ttsVolume: "TTS Volume",
  prodName: "Product Name",
  prodCode: "Product Code",
  interestRate: "Interest Rate",
  status1: "Product Status",
  stageFlag: "Installment",
  stageType: "Term Type",
  repaymentMethod: "Payment Type",
  all: "Total",
  count: "条记录不用翻译",
  orgName: "Dept",
  orgCode: "部门编号-未翻译",
  customerName: "Customer Name",
  gender: "Customer Gender",
  company: "Contract Entity",
  customerStage: "Case Stage",
  lastCallTime: "Last Contact Time",
  belongTenantTime: "Tenant Assignment Time",
  followUpType: "Followup Method",
  belongUserInfoName: "Customer Manager",
  phone1: "Customer Phone",
  level1: "Case Level",
  send: "Send",
  label: "Flag",
  markType: "Flag type",
  model: "Model",
  modelName: "Model Name",
  modelCode: "Model Code",
  modelType: "Model Type",
  remark1: "Comment",
  nomatchScore: "Threshold Unmatched",
  classfiy: "Classification",
  classfiyName: "Name",
  classfiyCode: "Code",
  pretestCallRemind: "预测式外呼活动提醒-未翻译",
  pretestRemind: "请签入系统电话就绪准备参加-未翻译",
  notJoin: "Do not participate",
  join: "Participate",
  beginTips: "活动即将开始-未翻译",
  moveInCall: "请先签入,再拨打电话",
  mustPhone: "Phone number can not be empty!",
  currentCalling: "当前正处于通话中"
}