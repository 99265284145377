import { defineStore } from 'pinia'
import { RouteRecordRaw } from 'vue-router'
import { shiki, Api } from '../api'
import {
  IndexCode,
  StorageEnum,
  OperationModelEnum,
  OrgTypeEnum
} from '../enum'
import factory from '../router/factory'
import router from '../router'
import { base, core, storage } from '../share'

export type User = {
  indexPermissions?: {
    deptFlag?: '0' | '1'
    userFlag?: '0' | '1'
    workplaceFlag?: '0' | '1'
  }
  defaultOrgId: string
  defaultOrgName: string
  menuList: RouteRecordRaw[]
  indexCode: IndexCode
  loginName: string
  avatarFileName?: string
  avatarRelativePath?: string
  userInfoId: string
  tenantName: string
  userInfoCode: string
  userInfoName: string
  tenantId: string
  token: string
  operationModel?: OperationModelEnum // 运营模式
  orgType?: OrgTypeEnum // 组织类型
  ownerFlag?: '0' | '1'
}

export const userDefault: User = {
  defaultOrgId: '',
  defaultOrgName: '',
  menuList: [] as RouteRecordRaw[],
  indexCode: '' as IndexCode,
  loginName: '',
  userInfoId: '',
  tenantName: '',
  userInfoCode: '',
  userInfoName: '',
  tenantId: '',
  token: '',
  operationModel: '' as OperationModelEnum,
  orgType: '' as OrgTypeEnum
}

const user: User = storage.getLocal(StorageEnum.User) || base.clone(userDefault)

export const useUserStore = defineStore('user', {
  state: () => {
    return user as User
  },
  actions: {
    // 更新权限
    async updateAuth() {
      if (!this.token) {
        setTimeout(() => {
          router.push({ path: '/login' })
        }, 0)
        return
      }
      const path = Api.cs + 'ath/getAuth'
      const { data } = await shiki.getData(path)
      if (data) {
        let menuList = data.menuList as RouteRecordRaw[]
        // children中的path去除开始和结尾的斜杠
        menuList = menuList?.map((item) => {
          item.children?.forEach((child) => {
            const path = child?.path
            if (path) {
              child.path = '/' + path?.replace(/^\/|\/$/g, '')
            }
          })
          return item
        })
        const project = core.getProject()
        const indexCodeMap = data.indexCodeMap || {}
        const indexCode = indexCodeMap[project.replace('stori', '')]
        this.indexCode = indexCode
        this.menuList = menuList || []
        user.indexCode = indexCode
        user.menuList = menuList || []
        user.ownerFlag = data.ownerFlag
        user.indexPermissions = data.indexPermissions
        this.ownerFlag = data.ownerFlag
        // 缓存
        storage.setLocal(StorageEnum.User, user)
        // 重新创建路由
        factory()
        return true
      }
    }
  }
})
