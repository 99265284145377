export default {
  downloadTemplate: '下载模版',
  exportTemplate: '导出模版',
  icon: '图标',
  setting: '设置',
  sort: '排序',
  transfer: '转移',
  close: '关闭',
  closeAll: '关闭所有',
  closeOther: '关闭其他',
  version: '版本号',
  quit: '退出',
  notice: '通知',
  business: '业务消息',
  private: '私聊消息',
  group: '群聊消息',
  top: '置顶',
  jobNo: '工号',
  extension: '座席',
  agentStatus: '座席状态',
  userStatus: '员工状态',
  moveIn: '已签入',
  moveOut: '已签出',
  number: '号码',
  task: '任务',
  idle: '空闲中',
  dialing: '拨打中',
  incomingCall: '来电振铃中',
  calling: '接通中',
  offline: '座席离线',
  activeing: '启用中',
  hangup: '挂断',
  answer: '接听',
  stopAutoCall: '暂停自动外呼',
  reCheckIn: '当前座席已经在其他终端登录,是否重新签入?',
  continueMoveIn: '继续签入',
  cancelMoveIn: '取消签入',
  confirmDisconnect: '确定签出?',
  notVacantAgent: '暂无空闲座席',
  queueEnd: '呼叫队列已结束',
  nextFail: '自动拨打下一条失败!',
  sipClientType: '终端类型',
  phone: '请输入号码',
  call: '拨打',
  tenantName: '租户名称',
  userInfoName: '员工姓名',
  defaultLoginName: '登录名',
  deptName: '部门',
  positionName: '岗位',
  userInfoCode: '员工号',
  phoneNum: '手机号',
  fileMd5: 'md5',
  originalFilename: '源文件名',
  fileName: '文件名',
  relativePath: '路径',
  operation: '操作',
  importType: '导入类型',
  file: '文件',
  most: '不超过',
  onlyUpload: '只能上传',
  templateType: '模版类型',
  uploadHint: '将文件拖拽至此或点击上传',
  limitHint: '文件大小不能超过',
  mustFile: '请选择要上传的文件',
  mustImportType: '请选择导入类型',
  beyondTip: '上传数量超过限制',
  add: '功能新增',
  deleteAllTips: '此操作将永久删除该功能节点以及下属功能, 是否继续?',
  function: '功能',
  functionAndInterface: '功能与功能接口关系',
  level: '层级',
  parentCode: '父编号',
  parentName: '父机构名称',
  titleZh: '功能名称',
  serialNo: '序号',
  orgType: '节点类型',
  aliasName: '别名',
  name: '名称',
  organization: '机构',
  post: '岗位',
  bizLine: '业务线',
  dataPermission: '数据权限',
  deleteTips: '是否删除岗位?',
  add1: '组织新增',
  deleteAllTips1: '确定此操作将永久删除该机构节点以及下属岗位, 是否继续?',
  sysKey: '项目名',
  indexCode: '首页',
  title: '首页管理',
  index: '首页',
  ctsn: '客户任务号',
  taskSn: '任务流水号',
  taskType: '任务类型',
  userInfoId: '操作员id',
  batchId: '批次id',
  firstStrategyId: '外呼策略',
  outboundStrategyId: '外呼策略',
  secondStrategyId: '重呼策略',
  batchOrder: '批次序号',
  year: '年份',
  month: '月份',
  date: '任务日期',
  dateHour: '小时',
  hour: '小时',
  callSys: '引擎类型',
  createType: '创建类型',
  businessScenario: '业务场景',
  industryCode: '行业编号',
  taskClassify: '任务类型',
  status: '任务状态',
  editStatus: '编辑状态',
  statusFailType: '任务状态失败种类',
  failReason: '失败原因',
  isForcibly: '是否强制完成',
  autoDedupFlag: '自动去重',
  smsSendFlag: '是否发送短信',
  hangupSmsId: '短信模板id',
  hangupSmsName: '短信模板名称',
  totalCount: '号码量',
  formalCount: '正式号码量',
  testCount: '测试号码量',
  addDataSuccCount: '正式数据总数',
  addDataFailCount: '失败数',
  processCount: '已拨打',
  cancelCallCount: '已停播',
  blackListCount: '黑名单数量',
  dedupCount: '去重数量',
  succSyncCount: '同步完成总数',
  succSyncRate: '同步完成率',
  connCount: '接通数',
  connRate: '接通率',
  totalTalkingTime: '总通话时长',
  maxTalkingTime: '最大通话时长',
  avgTalkingTime: '平均通话时长',
  feeUnit: '单位',
  unitCount: '单位基数',
  unitPrice: '单价',
  totalPrice: '合计',
  totalFeeCount: '计费分钟数',
  ultraShortCount: '等于一秒通话次数',
  ultraShortRatio: '等于一秒通话占比',
  shorterCount: '较短通时次数',
  shorterRatio: '较短通时占比',
  normalCount: '普通通时次数',
  normalRatio: '普通通时占比',
  longerCount: '较长通时次数',
  longerRatio: '较长通时占比',
  ultraLongCount: '60秒以上通话次数',
  ultraLongRatio: '60秒以上通话占比',
  earliestTime: '最早外呼时间',
  latestTime: '最晚外呼时间',
  outboundNo: '外呼线路',
  callResultStatus: '外呼结果文件状态',
  callResultFilePath: '外呼结果文件路径',
  callSysSpeechId: '智能项目流水号',
  speechId: '话术ID',
  speechCode: '话术编码',
  speechName: '话术名称',
  productId: '产品id',
  productCode: '产品code',
  beginTime: '任务开始时间',
  endTime: '任务结束时间',
  taskGroup: '任务组',
  taskGroupStatus: '任务组状态',
  lastTaskGroup: '上一个任务组',
  taskGroupOrder: '任务组序号',
  taskGroupCallNo: '任务组拨打序号',
  lastTaskId: '上一遍任务id',
  currentFilterType: '新任务外呼筛选类型',
  currentFilterContent:
    '新任务外呼筛选规则的内容,可以存储标签或通话结果以,隔开',
  automationFlag: '自动化开关',
  isCallBack: '是否回传',
  recallFlag: '是否重呼',
  recallType: '重呼类型',
  currentRecallTimes: '当前重呼次数',
  originalRecallTimes: '原任务重呼次数',
  recallTimes: '重呼次数',
  recallInterval: '重呼间隔',
  recallNumGroup: '重呼线路',
  recallNumSmsSendFlag: '重呼发送短信标志',
  recallNumSmsId: '重呼发送短信模板',
  filterType: '筛选类型',
  filterContent: '筛选规则的内容,可以存储标签或通话结果以,隔开',
  startType: '启动类型',
  timedStartTime: '定时启动时间',
  excludeRegionOperator: '排除的地区/运营商',
  changeFlag: '是否改良',
  changeTime: '改良变更时间',
  callerGroupName: '主叫组名称',
  failCall: '外呼失败流水',
  notCalled: '未呼流水',
  dataFile: '数据文件',
  upload: '导入号码',
  strategyFlag: '是否使用策略',
  speechTemplate: '话术模板',
  fileTips: '文件不能为空，请上传文件',
  endTips: '选中的任务？',
  automationTips: '自动化？',
  newRecall: '新任务重呼',
  originRecall: '原任务重呼',
  editingFlag: '是否编辑中',
  editingName: '编辑人员',
  originalRecallFlag: '是否有原任务重呼',
  completeEdit: '强制完成编辑中的任务',
  insertTestData: '插入测试数据',
  insertTestDataTips: '只有 待发布 和 失败 状态的任务可以修改!',
  sendSMS: '发送短信',
  alarmSetting: '报警设置',
  callerGroupId: '首遍呼叫主叫组',
  concurrentNumber: '并发数：',
  taskSource: '任务来源',
  name1: '任务名称',
  publish: '发布',
  revokePublish: '撤销发布',
  speechConfig: '话术配置',
  recordCheck: '录音校验并下发',
  recordUpload: '录音导入',
  pack: '打包',
  remark: '话术描述',
  releaseFlag: '是否发布',
  createByName: '创建人',
  updateTime: '最近更新时间',
  baseInfo: '基础信息',
  recordingType: '录音类型',
  lastNamePrivateFlag: '姓氏私有',
  understandWay: '语义理解方式',
  speechMatchType: '问法匹配类型',
  waitingTime: '等待时长',
  pauseTime: '停顿时长',
  recordSilenceThreshold: '开始能量阈值',
  eosSilenceThreshold: '结束能量阈值',
  interruptMode: '打断方式',
  interruptRecordSilenceThreshold: '打断能量阈值',
  interruptPauseTime: '打断静音超时',
  interruptIntervalMs: '打断间隔毫秒',
  engineSetting: '引擎设置',
  ttsType: 'TTS通道',
  ttsSpeakerCode: '发音人编号',
  asrType: 'ASR通道',
  ttsSpeed: 'TTS语速',
  ttsIntonation: 'TTS语调',
  ttsVolume: 'TTS音量',
  prodName: '产品名称',
  prodCode: '产品编码',
  interestRate: '产品利率',
  status1: '产品状态',
  stageFlag: '是否分期',
  stageType: '分期类型',
  repaymentMethod: '还款方式',
  all: '全部',
  count: '条记录',
  orgName: '部门名称',
  orgCode: '部门编号',
  customerName: '客户姓名',
  gender: '客户性别',
  company: '合同签署主体',
  customerStage: '客户阶段',
  lastCallTime: '最近一次通话时间',
  belongTenantTime: '分配租户时间',
  followUpType: '跟进方式',
  belongUserInfoName: '客户经理',
  phone1: '客户手机',
  level1: '客户层级',
  send: '发送',
  label: '标签',
  markType: '标记类型',
  model: '模型',
  modelName: '模型名称',
  modelCode: '模型编码',
  modelType: '模型类型',
  remark1: '备注',
  nomatchScore: '未匹配阈值',
  classfiy: '分类',
  classfiyName: '分类名称',
  classfiyCode: '分类编码',
  pretestCallRemind: '预测式外呼活动提醒',
  pretestRemind: '请签入系统电话就绪准备参加',
  notJoin: '不参加',
  join: '参加',
  beginTips: '活动即将开始',
  moveInCall: '请先签入,再拨打电话',
  mustPhone: '号码不能为空',
  currentCalling: '当前正处于通话中'
}
