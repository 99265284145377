export default {
  button: {
    add: "Nuevo",
    agree: "De acuerdo",
    activation: "Activar",
    all: "Total",
    allChecked: "Seleccionar Todo",
    assign: "Asigne",
    batch: "Lote",
    cancel: "Cancelar",
    claim: "Reclamación",
    confirm: "Confirmar",
    copy: "Copiar",
    copyAdd: "Copiar para Añadir",
    close: "Cerrar",
    closeSearch: "Contraer",
    end: "Finalizado",
    create: "Crear",
    delete: "Borrar",
    detail: "Detalle",
    downloadTemplate: "Descargar",
    download: "Descargar",
    edit: "Editar",
    empty: "Vacío",
    enable: "Habilitar",
    examinePass: "Aprobado",
    examineReject: "Rechazada",
    export: "Exportar",
    filter: "Seleccione",
    finish: "Completado",
    fold: "Contraer",
    forbidden: "Desactivar",
    generate: "Generar",
    handle: "En Curso",
    import: "Importar",
    lock: "Bloquear",
    login: "Inicio de Sesión",
    query: "Buscar ",
    off: "Cerrar",
    open: "Inicio",
    publish: "Publicar",
    resume: "Reanudar",
    refresh: "Actualizar",
    revoke: "Retirar",
    reject: "Rejec",
    revokeRelease: "Deshacer Publicar",
    sort: "Ordenar",
    save: "Guardar",
    spread: "Contraer",
    spreadSearch: "Desplegar",
    startUp: "Iniciar",
    stop: "Pausado",
    submit: "Enviar",
    temporarySave: "Puesta en Escena",
    transfer: "Transferencia",
    upload: "Cargar",
    unlock: "Desbloquear",
    void: "Anular"
  },
  common: {
    agent: "Agencia",
    alter: "Cambiar",
    case: "Caso",
    createBy: "Creado por",
    customer: "Cliente",
    department: "Departamento",
    entrust: "Producto",
    entrustProduct: "Producto",
    labelQuery: "Bandera Consulta",
    notData: "¡No hay datos!",
    operate: "Acción",
    product: "Producto",
    quantity: "Recuento",
    remark: "Comentario",
    speech: "Script",
    sysCode: "Tipo de Sistema",
    tenant: "Oficina",
    workplace: "Oficinas",
    user: "Agente",
    task: "Tarea",
    createByName: "Creado por",
    createTime: "Crear Tiempo",
    updateByName: "Operador",
    updateTime: "Tiempo de Proceso"
  },
  tips: {
    autoCallEnd: "¡Pausa primero ya que su estado es llamada automatizada!",
    copyFail: "Copia Fallida",
    copySuccess: "Copia finalizada",
    enter: "Introduzca",
    pick: "Seleccionar",
    required: "¡no puede estar vacío!",
    select: "Seleccionar",
    confirmDelete: "¿Confirmar eliminación?"
  },
  unit: {
    day: "Día",
    individual: "个不用翻译",
    millisecond: "Milésimas de Segundo",
    minute: "Minuto",
    percentage: "%",
    piece: "件不用翻译",
    position: "byte",
    tenThousandYuan: "Diez Mil",
    second: "Segundo",
    yuan: "Peso",
    rice: "Contador"
  }
}