import utils from '../share/utils'

const aimiConfig = {
  uat: {
    baseUrl: 'uatpsaaimi.cuimitech.com',
    fsUrl: 'vue3uat.cuimitech.com',
    fsPort: '7443'
  },
  demo: {
    baseUrl: 'demopsaaimi.cuimitech.com',
    fsUrl: 'demofs.cuimitech.com',
    fsPort: '7443'
  },
  pure: {
    baseUrl: 'psawx.cuimitech.com',
    fsUrl: 'prodfs.cuimitech.com',
    fsPort: '7443'
  },
  prod: {
    baseUrl: 'psawx.cuimitech.com',
    fsUrl: 'prodfs.cuimitech.com',
    fsPort: '7443'
  }
}
const wxConfig = {
  uat: {
    baseUrl: 'uatpsawx.cuimitech.com',
    fsUrl: 'vue3uat.cuimitech.com',
    fsPort: '7443'
  },
  demo: {
    baseUrl: 'demoptawx.cuimitech.com',
    fsUrl: 'demofs.cuimitech.com',
    fsPort: '7443'
  },
  pure: {
    baseUrl: 'psawx.cuimitech.com',
    fsUrl: 'prodfs.cuimitech.com',
    fsPort: '7443'
  },
  prod: {
    baseUrl: 'psawx.cuimitech.com',
    fsUrl: 'prodfs.cuimitech.com',
    fsPort: '7443'
  }
}
const storiAimiConfig = {
  uat: {
    psa_baseUrl: 'platform-ai.cuimitech.com',
    pta_baseUrl: 'admin-ai.cuimitech.com',
    fsUrl: 'ai.cuimitech.com',
    fsPort: '7443'
  },
  prod: {
    psa_baseUrl: 'platform-ai.cuimitech.com',
    pta_baseUrl: 'admin-ai.cuimitech.com',
    fsUrl: 'ai.cuimitech.com',
    fsPort: '7443'
  }
} as any
const storiWxConfig = {
  uat: {
    psa_baseUrl: 'manager-virilis.cuimitech.com',
    pta_baseUrl: 'agent-virilis.cuimitech.com',
    fsUrl: 'virilisfs.cuimitech.com',
    fsPort: '7443'
  },
  prod: {
    psa_baseUrl: 'manager-virilis.cuimitech.com',
    pta_baseUrl: 'agent-virilis.cuimitech.com',
    fsUrl: 'virilisfs.cuimitech.com',
    fsPort: '7443'
  }
} as any
const zhijieConfig = {
  uat: {
    baseUrl: 'uatpsawx.cuimitech.com',
    fsUrl: 'vue3uat.cuimitech.com',
    fsPort: '7443'
  },
  demo: {
    baseUrl: 'demopsawx.cuimitech.com',
    fsUrl: 'demofs.cuimitech.com',
    fsPort: '7443'
  },
  pure: {
    baseUrl: 'psazhijie.cuimitech.com',
    fsUrl: 'prodfs.cuimitech.com',
    fsPort: '7443'
  },
  prod: {
    baseUrl: 'psazhijie.cuimitech.com',
    fsUrl: 'prodfs.cuimitech.com',
    fsPort: '7443'
  }
}
const customerConfig: any = {
  jingzhong: {
    demo: {
      baseUrl: 'jingzhong.cuimitech.com',
      fsUrl: 'demofs.cuimitech.com',
      fsPort: '7443'
    },
    prod: {
      baseUrl: 'jingzhong.cuimitech.com',
      fsUrl: 'prodfs.cuimitech.com',
      fsPort: '7443'
    }
  },
  yunke: {
    prod: {
      baseUrl: 'jingzhong.cuimitech.com',
      fsUrl: 'prodfs.cuimitech.com',
      fsPort: '7443'
    }
  },
  yifan: {
    prod: {
      baseUrl: '10.25.88.100',
      fsUrl: '10.25.88.102',
      fsPort: '5066'
    }
  },
  qiyuan: {
    prod: {
      baseUrl: 'qiyuan.cuimitech.com',
      fsUrl: 'prodfs.cuimitech.com',
      fsPort: '7443'
    }
  },
  xierongshuke: {
    prod: {
      baseUrl: 'xierongshuke.cuimitech.com',
      fsUrl: 'prodfs.cuimitech.com',
      fsPort: '7443'
    }
  },
  huanbei: {
    prod: {
      baseUrl: 'huanbei.cuimitech.com',
      fsUrl: 'prodfs.cuimitech.com',
      fsPort: '7443'
    }
  },
  hbnsh: {
    demo: {
      baseUrl: 'demohbnshpsawx.cuimitech.com',
      fsUrl: 'demofs.cuimitech.com',
      fsPort: '7443'
    },
    prod: {
      baseUrl: 'hbnshpsawx.cuimitech.com',
      fsUrl: 'prodfs.cuimitech.com',
      fsPort: '7443'
    }
  },
  dy: {
    demo: {
      baseUrl: 'demopsady.cuimitech.com',
      fsUrl: 'demofs.cuimitech.com',
      fsPort: '7443'
    }
  }
}

type Stage = keyof typeof aimiConfig
type Project = 'aimi' | 'wx' | 'zhijie' | 'storiaimi' | 'storiwx'
type ApiType = 'psa' | 'pta'
type Config = {
  baseUrl: string
  fsUrl: string
  fsPort: string
}

function getConfig(stage: Stage, project: Project): Config {
  const customer = utils.getCustomerName()
  if (customer) {
    return customerConfig[customer][stage as 'prod']
  }
  if (project === 'aimi') {
    return aimiConfig[stage]
  } else if (project === 'wx') {
    return wxConfig[stage]
  } else if (project === 'storiaimi') {
    return storiAimiConfig[stage]
  } else if (project === 'storiwx') {
    return storiWxConfig[stage]
  } else if (project === 'zhijie') {
    return zhijieConfig[stage]
  }
  return aimiConfig[stage]
}

export function getBaseUrl(stage: Stage, project: Project, apiType: ApiType) {
  const config = getConfig(stage, project) as any
  if (['storiaimi', 'storiwx'].includes(project)) {
    return config[`${apiType}_baseUrl`]
  }
  if (apiType === 'pta') {
    return config.baseUrl.replace('psa', 'pta')
  }
  return config.baseUrl
}

export function getBaseApiUrl(
  stage: Stage,
  project: Project,
  apiType: ApiType
) {
  const url = getBaseUrl(stage, project, apiType)
  return `https://${url}/`
}

export default { getConfig, getBaseUrl, getBaseApiUrl }
