// 租户分类
export enum TenantClassify {
  AgentTenant = 'agentTenant', // 人工外呼租户
  Entrust = 'entrust', // 资产方
  MasterWorkPlace = 'masterWorkPlace', // 集团职场
  MasterSingleWorkPlace = 'masterSingleWorkPlace', // 所属职场
  Organization = 'organization', // 组织
  AiCsr = 'aiCsr', // 智能座席客户
  Supplier = 'supplier', // 供应商
  SmsSupplier = 'smsSupplier', // 短信供应商
  Tenant = 'tenant', // 租户
  TenantTemplate = 'tenantTemplate', // 租户模板
  Workplace = 'workplace', // 人工座席职场
  MuCsr = 'muCsr' // 人工座席客户
}
// 租户类型
export enum TenantCategoryEnum {
  AiCsr = '10', // 智能座席客户
  Workplace = '11', // 职场
  Entrust = '12', // 资产方
  Agent = '13', // 代理商
  Supplier = '14', // 供应商
  Tenant = '99' // 租户
}
// 分配类型
export enum AssignType {
  Workplace = '13', // 职场
  Department = '15', // 部门
  Personal = '16' // 个人
}
// 分配操作类型
export enum AssignOperateType {
  Assign = 'assign', // 分配
  Transfer = 'transfer' // 转移
}
// 分配种类
export enum AssignKind {
  Case = 'case', // 案件
  Customer = 'customer' // 客户
}
// 层级
export enum Hierarchy {
  Platform = '11', // 平台
  PlatformAssignBatch = '12', // 平台分配批次
  Tenant = '13', // 职场
  Org = '15', // 部门
  User = '16' // 个人
}
// 案件类型
export enum CaseDataType {
  Case = '11', // 案件
  CaseBatch = '12' // 案件批次
}
// 系统类型
export enum SysCode {
  Platform = '10', // 平台
  Admin = '11' // 后台
}
// 功能类型
export enum FunType {
  System = '10', // 系统
  Function = '11', // 功能
  Page = '12', // 页面
  Global = '20', // 全局功能
  FirstLevelMenu = '30', // 一级菜单
  SecondLevelMenu = '31', // 二级菜单
  RemoteMenu = '32' // 远程菜单
}
// 运营模式
export enum OperationModelEnum {
  Group = '10', // 集团运营模式
  Alone = '11' //独立运营模式
}
// 组织类型
export enum OrgTypeEnum {
  Header = '10', // 总部
  Branch = '11' //分部
}
// 队列类型
export enum QueueType {
  Tel = '11', // 电催
  Law = '12', // 法催
  Customer = '13' // 客户
}
// 文章类型
export enum ArticleTypeEnum {
  SystemRelease = '101', // 系统更新
  OperateInstruction = '102', // 操作教程
  SystemNotice = '103' // 公告
}
// 文件资源类型
export enum FileBizTypeEnum {
  UserInfoPic = '111110', // 头像
  ArticleSc = '124010', // 文章静态资源
  ArticlePage = '124011', // 文章页面
  ArticlePic = '124012', // 文章图片
  ArticleAudio = '124013', // 文章音频
  ArticleVideo = '124014', // 文章音频
  CacheRefreshHandle = '123110', // 缓存刷新
  Category = '162210', // 分类
  CollectionOrder = '471610', // 案件材料
  DataTypeMapping = '122910', // 数据类型映射
  DataHandleSetting = '113510', // 数据处理
  ScheduledTask = '141110', // 定时任务
  Speech = '161110', // 话术
  Stvp = '162410', // 录音包
  Template = '122510', // 模板
  TemplateFieldDataHandleType = '122710', // 数据处理类型
  TemplateFieldDataType = '122810', // 数据类型
  TemplateType = '122410', // 模板类型
  WebPage = '124610' // 页面配置
}
// 项目类型
export enum ObjectTypeEnum {
  Object = 'object', // 全部项目
  MyObject = 'myObject' // 我的项目
}
// sip客户端类型
export enum SipClientTypeEnum {
  Software = '10', // 软电话
  Web = '11', // 网页电话
  WorkPhone = '12' // 工作手机
}
