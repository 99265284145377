type CustomerName =
  | 'jingzhong'
  | 'yunke'
  | 'yifan'
  | 'qiyuan'
  | 'xierongshuke'
  | 'huanbei'
  | 'hbnsh'
  | 'dy'

export default {
  // 获取客户名称
  getCustomerName(): CustomerName | undefined {
    if (typeof window === 'undefined') {
      return undefined
    }
    const href = location.href
    // 一凡
    const isIsfan = href.includes('10.25.88.100')
    if (isIsfan) {
      return 'yifan'
    }
    const list = [
      'jingzhong',
      'yunke',
      'qiyuan',
      'xierongshuke',
      'huanbei',
      'hbnsh',
      'dy'
    ] as CustomerName[]
    return list.find((item) => {
      return href.indexOf(item) >= 0
    })
  },
  // 是否处于electon中
  inElectron() {
    const userAgent = navigator.userAgent.toLowerCase()
    return userAgent.indexOf(' electron/') > -1
  },
  // 获取设备id
  getMachineId() {
    return window.electron.machineId
  }
}
