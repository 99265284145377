const notFound = () => import('../../app-admin/src/views/app/404/index.vue') // 404
const login = () => import('../../app-admin/src/views/app/login/index.vue') // 登陆
const auth = () => import('../../app-admin/src/views/app/auth/index.vue') // 授权
const flowDesign = () =>
  import('../../app-admin/src/views/cxsdm/dm/flow-design/index.vue') // 流程设计
const test = () => import('../../app-admin/src/views/test/index.vue') // test

export default [
  {
    path: '/:pathMatch(.*)*',
    component: notFound
  },
  {
    path: '/login',
    component: login
  },
  {
    path: '/auth',
    component: auth
  },
  {
    path: '/flow-design',
    component: flowDesign
  },
  {
    path: '/test',
    component: test
  }
]
