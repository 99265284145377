import { ElMessage } from 'element-plus'
import { shiki, Api } from '../api'
import { InboundStatus, LangEnum, SipClientTypeEnum } from '../enum'
import lang from '../lang/index'
import { jssip, vuei18n } from '../plugs'
import { ctilink, sound } from '../share'
import { useCallStore } from '../store'

export default {
  // 初始化
  init(callback?: Function) {
    const callStore = useCallStore()
    const sipClientType = callStore.agentInfo.sipClientType
    if (sipClientType === SipClientTypeEnum.Software) {
      // ctilink初始化
      ctilink.init()
    } else if (sipClientType === SipClientTypeEnum.Web) {
      // 申请麦克风权限
      navigator.mediaDevices.getUserMedia({ audio: true })
      // jssip初始化
      jssip.init({
        // 注册成功
        registered() {
          // ctilink初始化
          ctilink.init()
          callback && callback()
        }
      })
    }
  },
  // 拨打电话
  callOut(phone?: string, callSn?: string) {
    const callStore = useCallStore()
    const agentInfo = callStore.agentInfo
    if (agentInfo.sipClientType === SipClientTypeEnum.WorkPhone) {
      // 工作手机
      const path = Api.crmmu + 'wp/workPhoneCall'
      shiki.postCode(path, { id: callSn })
    } else {
      // 软件电话和网页电话
      ctilink.callOut({
        callee: phone,
        extField: { callSn }
      })
    }
  },
  // 能否拨打电话
  canCall() {
    const locale = vuei18n.global.locale.value as LangEnum
    const messages = lang[locale]
    const callStore = useCallStore()
    if (!callStore.online) {
      ElMessage.warning(messages.moveInCall)
      return false
    }
    if (callStore.calling) {
      ElMessage.warning(messages.currentCalling)
      return false
    }
    return true
  },
  // 接听电话
  answer() {
    jssip.answer()
  },
  // 切换呼入状态
  switchStatus(status: InboundStatus) {
    ctilink.switchStatus(status)
  },
  // 挂断
  hangUp() {
    const callStore = useCallStore()
    const agentInfo = callStore.agentInfo
    if (agentInfo.sipClientType === SipClientTypeEnum.WorkPhone) {
      const path = Api.crmmu + 'wp/pcHangup'
      shiki.postCode(path, { id: callStore.business.id })
    } else {
      ctilink.hangup()
      jssip.terminateSessions()
    }
    sound.pause()
  },
  // 签出
  logout() {
    jssip.unregister()
    ctilink.logout()
  },
  // jssip注销
  jssipUnregister() {
    jssip.unregister()
  }
}
