import { core, utils, vuei18n } from 'chant'
import {
  normal as normalEn,
  huanbei as huanbeiEn,
  pure as pureEn
} from './company/en'
import {
  normal as normalEs,
  huanbei as huanbeiEs,
  pure as pureEs
} from './company/es'
import {
  normal as normalZh,
  huanbei as huanbeiZh,
  pure as pureZh
} from './company/zh'

const customerName = utils.getCustomerName()

let normal = {
  en: normalEn,
  es: normalEs,
  zh: normalZh
}
// 联通
if (customerName === 'huanbei') {
  normal = {
    en: huanbeiEn,
    es: huanbeiEs,
    zh: huanbeiZh
  }
}

const pure = {
  en: pureEn,
  es: pureEs,
  zh: pureZh
}

const lang: any = core.isPure() ? pure : normal

for (let item in lang) {
  vuei18n.global.mergeLocaleMessage(item, lang[item])
}
