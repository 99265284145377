import { defineStore } from 'pinia'
import { reactive } from 'vue'

export const useChatStore = defineStore('chat', () => {
  const state = reactive({
    projectId: '' // 项目id
  })

  return { state }
})
