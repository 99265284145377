import { App } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import { createPinia } from 'pinia'
import interceptor from './api/interceptor'
import components from './components/index'
import factory from './router/factory'
import { vuei18n, watermark } from './plugs'
import router from './router'
import { base, bus, core, im, utils } from './share'
import { Bus } from './enum'
import { useAppStore, useUserStore } from './store'
// element-plus主题
import './style/element-var.scss'

type Modules = Record<string, () => Promise<{ [key: string]: any }>>
type Options = {
  modules: Modules
  routes?: RouteRecordRaw[]
}

async function install(app: App<Element>, options: Options) {
  // 拦截器
  interceptor()
  // use
  app.use(createPinia())
  app.use(vuei18n)
  // 路由动态加载
  options?.routes?.forEach((item) => {
    router.addRoute(item)
  })
  // 保存路由模块
  const appStore = useAppStore()
  appStore.pageModules = options.modules
  // 不用获取权限的页面
  const path = location.pathname
  const exclude = ['/login', '/auth']
  if (exclude.includes(path)) {
    factory()
  } else {
    // 更新用户权限
    const userStore = useUserStore()
    await userStore.updateAuth()
  }
  app.use(router)
  // 全局组件初始化
  components(app)
  // 加载logo
  loadLogo()
  // 通知页面渲染
  bus.emit(Bus.Mount)
  // 水印初始化
  watermark.init()
  // im初始化
  im.init()
}

// 加载logo
function loadLogo() {
  const appStore = useAppStore()
  const customerName = utils.getCustomerName()
  let logo
  if (customerName) {
    // 专属客户定制logo
    logo = `${customerName}/logo`
  } else {
    // 是否脱敏
    logo = core.isPure() ? 'logo_pure' : 'logo'
    // 获取项目名称
    const project = core.getProject()
    logo = `${project}/${logo}`
  }
  appStore.logo = `/logo/${logo}.png`
  // 动态加载logo
  base.loadLink(appStore.logo, 'icon')
}

export default { install }

export * from './export'
