export const normal = {
  company: {
    describe: '领先的智能语音解决方案提供商',
    name: '富泷智云',
    project: '富泷智云'
    // describe: '领先的智能语音解决方案提供商',
    // name: '新闪智能',
    // project: '新闪智能语音'
  }
}

export const huanbei = {
  company: {
    describe: '领先的智能语音解决方案提供商',
    name: '',
    project: '智能语音'
  }
}

export const pure = {
  company: {
    describe: '领先的智能语音解决方案提供商',
    name: '上海网络科技有限公司',
    project: '智能语音'
  }
}
