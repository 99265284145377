export default {
  button: {
    add: '新增',
    agree: '同意',
    activation: '激活',
    all: '全部',
    allChecked: '全选',
    assign: '分配',
    batch: '批量',
    cancel: '取消',
    claim: '认领',
    confirm: '确定',
    copy: '复制',
    copyAdd: '复制新增',
    close: '关闭',
    closeSearch: '关闭搜索',
    end: '终止',
    create: '创建',
    delete: '删除',
    detail: '详情',
    downloadTemplate: '下载模版',
    download: '下载',
    edit: '编辑',
    empty: '清空',
    enable: '启用',
    examinePass: '审核通过',
    examineReject: '审核驳回',
    export: '导出',
    filter: '筛选',
    finish: '完成',
    fold: '折叠',
    forbidden: '禁用',
    generate: '生成',
    handle: '处理',
    import: '导入',
    lock: '锁定',
    login: '登录',
    query: '查询',
    off: '关闭',
    open: '开启',
    publish: '发布',
    resume: '恢复',
    refresh: '刷新',
    revoke: '撤销',
    reject: '驳回',
    revokeRelease: '撤销发布',
    sort: '排序',
    save: '保存',
    spread: '展开',
    spreadSearch: '展开搜索',
    startUp: '启动',
    stop: '暂停',
    submit: '提交',
    temporarySave: '暂存',
    transfer: '转移',
    upload: '上传',
    unlock: '解锁',
    void: '作废'
  },
  common: {
    agent: '代理商',
    alter: '修改',
    case: '案件',
    createBy: '创建人',
    customer: '客户',
    department: '部门',
    entrust: '资产方',
    entrustProduct: '资产方产品',
    labelQuery: '标签查询',
    notData: '暂无数据',
    operate: '操作',
    product: '产品',
    quantity: '数量',
    remark: '备注',
    speech: '话术',
    sysCode: '系统类型',
    tenant: '租户',
    workplace: '职场',
    user: '人员',
    task: '任务',
    createByName: '创建人',
    createTime: '创建时间',
    updateByName: '操作人',
    updateTime: '操作时间'
  },
  tips: {
    autoCallEnd: '当前正处于自动外呼状态,请先暂停',
    copyFail: '复制失败',
    copySuccess: '复制成功',
    enter: '请输入',
    pick: '选择',
    required: '不能为空',
    select: '请选择',
    confirmDelete: '确认删除?'
  },
  unit: {
    day: '天',
    individual: '个',
    millisecond: '毫秒',
    minute: '分钟',
    percentage: '%',
    piece: '件',
    position: '位',
    tenThousandYuan: '万元',
    second: '秒',
    yuan: '元',
    rice: '米'
  }
}
