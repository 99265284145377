import core from '../share/core'

function type() {
  return core.isPsa() ? 'psa' : 'pta'
}

export default {
  cc: `scxscc/${type()}/`,
  ci: `scxsci/${type()}/`,
  crm: `scxscrm/${type()}/`,
  crmes: `scxscrmes/${type()}/`,
  crmrr: `scxscrmrr/${type()}/`,
  crmmu: `scxscrmmu/${type()}/`,
  crmmurr: `scxscrmmurr/${type()}/`,
  cs: `scxscs/${type()}/`,
  cs_noauth: 'scxscs/noauth/',
  dtret: `scxsdtret/${type()}/`,
  dm: `scxsdm/${type()}/`,
  email: `scxsemail/${type()}/`,
  fs: `scxsfs/${type()}/`,
  im: `scxsim/${type()}/`,
  jyrpa: `scxsjyrpa/${type()}/`,
  lizhai: `scxslizhai/${type()}/`,
  lms: `scxslms/${type()}/`,
  log: `scxslog/${type()}/`,
  pm: `scxspm/${type()}/`,
  rpt: `scxsrpt/${type()}/`,
  sms: `scxssms/${type()}/`,
  tsk: `scxstsk/${type()}/`,
  tst: `scxstst/${type()}/`,
  vos: `scxsvos/${type()}/`,
  wht: `scxswht/${type()}/`
}
